import React, { FC } from "react";
import CoreModal from '@material-ui/core/Modal';
import styled from "styled-components";

interface Props {
    onClose: VoidFunction;
    open: boolean;
}

const Modal: FC<Props> = ({ children, open, onClose }) => {
    return (
        <CoreModal open={open} onClose={onClose}>
            <ModalContent>
                <Inner>
                    {children}
                </Inner>
            </ModalContent>
        </CoreModal>
    );
}

const ModalContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const Inner = styled.div`
    width: 400px;
    position: absolute;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
`;

export default Modal;